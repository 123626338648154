import React, { useEffect, useState } from "react";
import "../styles/Header.css";
import LinkedIn from "../img/linkedin.svg";
import AppleMusic from "../img/apple-music.svg";

function Header(){
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0); // Adjust the threshold as needed
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll); // Cleanup
    }, []);

    return(
        <header className={isScrolled ? "app-header shown" : "app-header"}>
            <div className="socials">
                <a href="https://music.apple.com/profile/dhrty" target="my_profile">
                    <img src={AppleMusic} alt="Apple music logo" className="socials-icon"/>
                </a>
                <a href="https://www.linkedin.com/in/rubén-mendoza-amado-4ab5642a6/" target="my_profile">
                    <img src={LinkedIn} alt="LinkedIn logo" className="socials-icon"/>
                </a>
            </div>
        </header>
    );
}

export default Header;