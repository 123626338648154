import React, { useState } from "react";
import "../styles/Contact.css";
import { ColorRing } from "react-loader-spinner";
import DisappearingAlert from "./DisappearingAlert";

function Contact( { sectionRef }){
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [project, setProject] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleProjectChange = (event) => {
        setProject(event.target.value);
    };

    const formData = {
        name: name,
        email: email,
        project: project
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setShowSuccessAlert(false);
        setShowErrorAlert(false);
        setShowLoading(true);

        fetch('https://animated-moon-417301.ue.r.appspot.com/request', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(data => {
            if (data.ok) {
                console.log("OK");
                setShowLoading(false);
                setShowSuccessAlert(true);
                //handleClose();
            } else {
                setShowLoading(false);
                setShowErrorAlert(true);
                console.log(data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setShowLoading(false);
            setShowErrorAlert(true);
        });
    };

    return(
        <div className='contact' data-aos="fade-up" data-aos-duration="2000" ref={sectionRef} id="contact-section">
            <form onSubmit={handleSubmit}  className='contact-form'>
                <h2>I'm ready to work with you</h2>
                <p><span className="smallest-text">Email me at </span>rma@rma-dev.com</p>
                <p className="smallest-text">or</p>
                <div className="input-group">
                    <input
                        className={name.trim() === '' ? 'input' : 'non-empty-input'}
                        type="text"
                        name="name"
                        required
                        value={name}
                        onChange={handleNameChange}/>
                    <label for="name" className={name.trim() === '' ? '' : 'non-empty'}>Name or company</label>
                </div>
                <div className="input-group">
                    <input
                        className={email.trim() === '' ? 'input' : 'non-empty-input'}
                        type="email"
                        name="email"
                        required
                        value={email}
                        onChange={handleEmailChange}/>
                    <label for="name" className={email.trim() === '' ? '' : 'non-empty'}>Email</label>
                </div>
                <div className="input-group">
                    <textarea
                        className={project.trim() === '' ? 'input' : 'non-empty-input'}
                        type="text"
                        name="project"
                        required
                        value={project}
                        onChange={handleProjectChange}
                    />
                    <label for="project" className={project.trim() === '' ? '' : 'non-empty'}>About your project</label>
                </div>
                {showErrorAlert &&
                    <DisappearingAlert severity="error" message="Oops! there was an error. Please try again." />
                }
                {showSuccessAlert &&
                    <DisappearingAlert severity="success" message="Your request has been received. Thanks!" />
                }
                <button>
                    {showLoading ?
                        <div className="loader">
                            <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#ffffff', '#e9ebea', '#ffffff', '#e9ebea', '#ffffff']}
                            />
                        </div>
                        :
                        'Send'
                    }
                </button>
            </form>
        </div>
    );
}

export default Contact;