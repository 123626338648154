import React from "react";
import "../styles/TechnologiesSection.css";

function TechnologiesSection({ title, technologies, duration }){

    const techs = [];
    for (let i = 0; i < technologies.length; i++) {
        techs.push(
            <img src={technologies[i].source} className='logo' alt={technologies[i].alt}/>
        );
    }

    return(
        <div className='techs' data-aos="fade-up" data-aos-duration={duration}>
            <h2 className='techs-title'>{title}</h2>
            <div className='techs-logos' data-aos="fade-up" data-aos-duration={duration + 1000}>
                {techs}
            </div>
        </div>
    );
}

export default TechnologiesSection;