import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Project1 from './img/project-1.png';
import Project2 from './img/project-2.png';
import Project3 from './img/project-3.png';
import Project4 from './img/project-4.jpg';
import Java from './img/java.png';
import JavaScript from './img/javascript.png';
import C from './img/c.png';
import Python from './img/python.svg';
import PHP from './img/php.png';
import Angular from './img/angular.png';
import ReactJS from './img/reactjs.png';
import Spring from './img/spring.png';
import OracleDB from './img/oracledb.png';
import MySQL from './img/mysql.png';
import SQLServer from './img/sqlserver.svg';
import PostreSQL from './img/postgresql.png';
import MongoDB from './img/mongodb.png';
import Git from './img/git.png';
import GitHub from './img/github.png';
import BitBucket from './img/bitbucket.png';
import Docker from './img/docker.png';
import TechnologiesSection from './components/TechnologiesSection';
import Contact from './components/Contact';
import Header from './components/Header';

import React, { useRef } from 'react';

function App() {
  AOS.init({ once: false });

  const languages = [
    {
      source: Java,
      alt: 'Java logo'
    },
    {
      source: JavaScript,
      alt: 'JavaScript logo'
    },
    {
      source: C,
      alt: 'C logo'
    },
    {
      source: Python,
      alt: 'Python logo'
    },
    {
      source: PHP,
      alt: 'PHP logo'
    }
  ];

  const frameworks = [
    {
      source: Angular,
      alt: 'Angular logo'
    },
    {
      source: ReactJS,
      alt: 'ReactJS logo'
    },
    {
      source: Spring,
      alt: 'Spring logo'
    }
  ];

  const databases = [
    {
      source: OracleDB,
      alt: 'OracleDB logo'
    },
    {
      source: MySQL,
      alt: 'MySQL logo'
    },
    {
      source: SQLServer,
      alt: 'SQLServer logo'
    },
    {
      source: PostreSQL,
      alt: 'PostreSQL logo'
    },
    {
      source: MongoDB,
      alt: 'MongoDB logo'
    }
  ];

  const other = [
    {
      source: Git,
      alt: 'Git logo'
    },
    {
      source: BitBucket,
      alt: 'BitBucket logo'
    },
    {
      source: GitHub,
      alt: 'GitHub logo'
    },
    {
      source: Docker,
      alt: 'Docker logo'
    }
  ];

  const projectsSectionRef = useRef(null);
  const contactSectionRef = useRef(null);

  const scrollToProjects = () => {
    if (projectsSectionRef.current) {
      projectsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToContact = () => {
    if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="app">
      <Header />
      <div className="app-main">
        <h1 data-aos="zoom-in" data-aos-offset="0" data-aos-duration="2000">Hi! I'm Ruben Amado,</h1>
        <h2 data-aos="zoom-in" data-aos-offset="0" data-aos-duration="3000">Software developer based in Mexico.</h2>
        <div  data-aos="zoom-in" data-aos-offset="0" data-aos-duration="3000">
          <button className='btn-my-projects' onClick={scrollToProjects}>My projects</button>
          <button className='btn-my-projects' onClick={scrollToContact}>Contact me</button>
        </div>
      </div>
      <div ref={projectsSectionRef} id="projects-section">
        <img src={Project1} className='img-project-preview' alt='Project preview' />
        <img src={Project2} className='img-project-preview' alt='Project preview' />
        <img src={Project3} className='img-project-preview' alt='Project preview' />
        <img src={Project4} className='img-project-preview' alt='Project preview' />
      </div>
      <TechnologiesSection
        title={'Programming languages'}
        technologies={languages}
        duration={2000}
      />
      <TechnologiesSection
        title={'Frameworks'}
        technologies={frameworks}
        duration={2000}
      />
      <TechnologiesSection
        title={'Database engines'}
        technologies={databases}
        duration={2000}
      />
      <TechnologiesSection
        title={'Other tools'}
        technologies={other}
        duration={2000}
      />
      <footer className="">
        <Contact sectionRef={contactSectionRef}/>
      </footer>
    </div>
  );
}

export default App;
