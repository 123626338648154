import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const DisappearingAlert = ({ message, severity = 'info' }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setOpen(false), 5000); // 5 seconds

    return () => clearTimeout(timeoutId); // Cleanup on unmount
  }, []);

  return (
    <Snackbar open={open} autoHideDuration={5000}>
      <Alert severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default DisappearingAlert;